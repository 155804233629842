<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <template v-for="(value, name, index) in InvestInfo">
        <card
          :prop="KeyMap[name]"
          :value="value"
          :key="index"
        ></card>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import card from "../card.vue";
export default {
  name: "dashboard",
  components: {
    card,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      KeyMap: "getKeyMap",
      InvestInfo: "getInvestInfo",
      InterestRate: "getInterestRate",
    }),
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";

.dashboard {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    @include phone {
      width: 100%;
    }
  }
  &__card {
    width: 40%;
    margin: 10px;
  }
}
</style>
