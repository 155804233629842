<template>
  <div class="card">
    <div class="prop item">
      {{ prop.name }}
    </div>
    <div class="value item">
      {{ value }} <span class="coin" v-if="prop.isDollar">NTD</span>
    </div>
    <div class="desc item"></div>
  </div>
</template>
<script>
export default {
  name: "card",
  components: {},
  props: {
    prop: {
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "@/scss/main.scss";

.card {
  flex-grow: 1;
  box-sizing: border-box;
  height: fit-content;
  background-color: $white;
  box-shadow: rgb(20 21 26 / 4%) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px,
    rgb(20 21 26 / 10%) 0px 0px 1px;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
  max-width: 400px;
  min-width: 260px;
  @include phone {
    min-width: 360px;
  }
  .item {
    width: fit-content;
  }
  .prop {
    font-size: 16px;
  }
  .value {
    margin-top: 5px;
    font-size: 22px;
    margin-left: 20px;
    .coin {
      font-size: 12px;
    }
  }
}
</style>
